<div class="card">
    <div class="card-header">
        <h2 class="mb-0">{{popupData.title}}</h2>
    </div>
    <div class="card-body">
        <form action="" [formGroup]="fullData">
            <ng-container *ngFor="let field of popupData.fields" [formGroup]="fullData">
                <ng-container [ngSwitch]="true">
                    <div class="inputWarp" *ngSwitchCase="field.type == 0 && field.fieldName!==Password"
                        [ngClass]="{'inputsWarpDiv':i>1}">
                        <label>{{field.fieldName}}</label>

                        <input
                            *ngIf="field.fieldName!='Password' && field.fieldName!='Mobile' && field.fieldName!='Email-id'"
                            type="text" placeholder={{field.placeholder}} class="inputType"
                            required="{{field.isRequired}}" [formControlName]="field.fieldName">

                        <input *ngIf="field.fieldName=='Password'" type="text" placeholder={{field.placeholder}}
                            class="inputType" required="{{field.isRequired}}" [formControlName]="field.fieldName"
                            pattern="(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                            title="Must contain at least one number and one symbol and one uppercase and lowercase letter, and at least 8 or more characters">

                        <input *ngIf="field.fieldName=='Mobile'" type="number" placeholder={{field.placeholder}}
                            class="inputType" required="{{field.isRequired}}" [formControlName]="field.fieldName">

                        <input *ngIf="field.fieldName=='Email-id'" type="email" placeholder={{field.placeholder}}
                            class="inputType" required="{{field.isRequired}}" [formControlName]="field.fieldName"
                            >
                    </div>
                    <div class="inputWarp" *ngSwitchCase="field.type == 1" [ngClass]="{'inputsWarpDiv':i>1}">
                        <label>{{field.fieldName}}</label>
                        <select formControlName="{{field.fieldName}}"  data-toggle="select" class="inputType" required="{{field.isRequired}}"
                            (change)="onSelectChange(field.fieldName, $event.target.value)" (ngModelChange)="onSelectChange(field, $event)">
                            <!-- <option value="" disabled selected>Select Option</option> -->
                            <option *ngFor="let val of field.valuesDropDown" [value]="val" >{{val}}</option>
                        </select>
                    </div>
                    <div class="inputWarp" *ngSwitchCase="field.type == 2" [ngClass]="{'inputsWarpDiv':i>1}">
                        <label>{{field.fieldName}}</label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" placeholder={{field.placeholder}}
                                class="inputType" value={{field.value}} required="{{field.isRequired}}"
                                [formControlName]="field.fieldName" multiple>
                            <label class="custom-file-label"> Select file</label>
                        </div>
                    </div>
                    <div class="inputWarp" *ngSwitchCase="field.type == 3" [ngClass]="{'inputsWarpDiv':i>1}">
                        <label>{{field.fieldName}}</label>
                        <input type="number" placeholder={{field.placeholder}} class="inputType"
                            [formControlName]="field.fieldName" required="{{field.isRequired}}">
                    </div>
                    <div class="inputWarp" *ngSwitchCase="field.type == 4" [ngClass]="{'inputsWarpDiv':i>1}">
                        <label>{{field.fieldName}}</label>
                        <textarea cols="30" rows="10" placeholder={{field.placeholder}} class="inputType"
                            [formControlName]="field.fieldName" required="{{field.isRequired}}"></textarea>
                    </div>
                    <div class="inputWarp" *ngSwitchCase="field.type == 5" [ngClass]="{'inputsWarpDiv':i>1}">
                        <label>{{field.fieldName}}</label>
                        <input type="date" placeholder={{field.placeholder}} class="inputType"
                            required="{{field.isRequired}}" [formControlName]="field.fieldName">
                    </div>
                    <div class="inputWarp" *ngSwitchCase="field.type == 6" [ngClass]="{'inputsWarpDiv':i>1}"
                        style="display: block;">
                        <label>{{field.fieldName}}</label>
                        <ngx-tags-input class="form-control input-lg" [removeLastOnBackspace]="removeLastOnBackspace"
                            mode="success" [(ngModel)]="tags" [ngModelOptions]="{standalone: true}" name="tags"
                            placeholder={{field.placeholder}}></ngx-tags-input>
                    </div>
                </ng-container>
            </ng-container>
        </form>
    </div>
</div>

<mat-dialog-actions>
    <button [disabled]="!fullData.valid" class="saveDetailsBtn" (click)="saveData()">save</button>
    <button class="closeBtn" (click)="close()">close</button>
</mat-dialog-actions>