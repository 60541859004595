<div class="card">
  <div class="card-header border-0">
    <div class="row align-items-center">
      <h3 class="mb-0 col-lg-3 col-7">{{tableTitle}}</h3>
      <div class="navbar-search navbar-search-light form-inline mr-sm-3 " id="navbar-search-main">
        <div class="form-group mb-0">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
            <input class="form-control" placeholder="Search..." type="text" [(ngModel)]="searchValue"
              (keyup)="applyFilter($event)" />
          </div>
        </div>
        <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main"
          aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="mb-0 col-lg-6 col-7 text-center" [formGroup]="range">
        <div class=" form-group d-inline-block">
          <label class=" form-control-label"> From Date </label>
          <input class=" form-control" placeholder="from" type="date" formControlName="fromDate">
        </div>
        <div class=" form-group d-inline-block ml-2">
          <label class=" form-control-label"> To Date </label>
          <input class=" form-control" placeholder="to" type="date" formControlName="toDate">
        </div>
        <a class=" btn btn-sm btn-neutral color-icl ml-2" (click)="applyDateFilter()"> Submit</a>
      </div>
    </div>
  </div>
  <mat-spinner *ngIf="loader"></mat-spinner>
  <div *ngIf="!loader">
    <div class="table-responsive" *ngIf="listData && listData.length>0; else noData">
      <table class="table align-items-center table-flush" mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="displayedCol.fieldName" *ngFor="let displayedCol of displayedColumns">
          <th class="thead-light" scope="col" mat-header-cell *matHeaderCellDef>
            {{displayedCol.fieldName}}
          </th>
          <td mat-cell *matCellDef="let element" [ngSwitch]="displayedCol.type">
            <span class="text-muted" *ngSwitchCase="2">
              <a *ngIf="element[displayedCol.fieldName] && element[displayedCol.fieldName].content"
                [href]="'data:image/jpeg;base64,'+element[displayedCol.fieldName].content"
                [download]="element[displayedCol.fieldName].imgName"> {{ element[displayedCol.fieldName].imgName }}</a>
            </span>
            <span class="text-muted" *ngSwitchCase="5">
              {{element[displayedCol.fieldName] }}
            </span>
            <span class="text-muted" *ngSwitchDefault>
              {{ element[displayedCol.fieldName] }}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="colNames"></tr>
        <tr mat-row *matRowDef="let row; columns: colNames;"></tr>
      </table>
      <mat-paginator [length]="total" [pageIndex]="currentPage" [pageSize]="resultsPageSize"
        [pageSizeOptions]="pageSizeOptions" (page)="pageChangedEvent($event)"></mat-paginator>
    </div>
    <ng-template #noData>
      <mat-divider></mat-divider>

      <mat-label class="no-data-placeholder">
        No Search result found
      </mat-label>
      <mat-divider></mat-divider>
    </ng-template>
  </div>
</div>