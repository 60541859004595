import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: { class: 'width-components' }
})
export class LoginComponent implements OnInit {
  public name: string;
  public password: string;
  public errorMessege: string;
  public showErrorMessege: boolean = false;
  private arrFormBuilder = {};
  public loginData: FormGroup = new FormGroup({});
  private arrFormBuilderChangePass = {};
  public changePasswordData: FormGroup = new FormGroup({});
  public isForceChangePassword: boolean;
  constructor(public fb: FormBuilder, private loginService: LoginService, private router: Router) {
    this.isForceChangePassword = false;
    if (this.loginService.isUserLogin()) {
      this.router.navigateByUrl('/promo-activity-report');
    }
    this.setControlFields();
    this.setChangePasswordControlsFields();
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
  }
  setControlFields() {
    this.arrFormBuilder = {};
    this.arrFormBuilder["name"] = ['', Validators.required];
    this.arrFormBuilder["password"] = ['', Validators.required];
    this.loginData = this.fb.group(this.arrFormBuilder);
  }
  setChangePasswordControlsFields() {
    this.arrFormBuilderChangePass = {};
    this.arrFormBuilderChangePass["email"] = ['', Validators.required];
    this.arrFormBuilderChangePass["old_password"] = ['', Validators.required];
    this.arrFormBuilderChangePass["new_password"] = ['', Validators.required];
    this.changePasswordData = this.fb.group(this.arrFormBuilderChangePass);
  }
  login() {
    if (!(this.loginData.controls["name"].value && this.loginData.controls["password"].value)) {
      this.errorMessege = "Please enter a username or password";
      this.showErrorMessege = true;
    }
    else {

      let name = this.loginData.controls.name.value;
      let password = this.loginData.controls.password.value
      let new_password = '';
      this.loginService.signIn(name.toString().toLowerCase(), password, new_password ? new_password : '',
        (res) => {
          if (this.loginService.isUserLogin()) {
            this.showErrorMessege = false;
            this.router.navigateByUrl('/promo-activity-report');
          }
          else {
            swal.fire('Sorry...', 'you are not authorized to access to the managment system.', 'error');
          }
        },
        (err) => {
          if (err.internalMessage === 'New password is required.') {
            this.isForceChangePassword = true;
          }
          if (err.internalMessage && err.internalMessage.includes('Incorrect username or password')) {
            this.showErrorMessege = true;
            this.errorMessege = 'Incorrect username or password';
          }
        })
    }
  }
  changePassword() {
    this.loginService.signIn(this.changePasswordData.controls.email.value.toLowerCase(),
      this.changePasswordData.controls.old_password.value,
      this.changePasswordData.controls.new_password.value,
      (res) => {
        console.log(res)
        if (this.loginService.isUserLogin()) {
          this.router.navigate(['/promo-activity-report']);
        }
        else {
          swal.fire('Sorry...', 'you are not authorized to access to the managment system.', 'error');
        }
      },
      (err) => {
        this.showErrorMessege = true;
        this.errorMessege = err.message;
      }
    );
  }
}
