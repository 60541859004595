import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { FormContentDataService } from 'src/app/services/form-content-data.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-mandi-campaign-report',
  templateUrl: './mandi-campaign-report.component.html',
  styleUrls: ['./mandi-campaign-report.component.scss']
})
export class MandiCampaignReportComponent implements OnInit, OnDestroy {
  public tableTitle: string = "Mandi Campaign Report";
  public listData = [];
  public displayedColumns: string[];
  public total: number;
  private componentId = 2;
  private subscriber;
  public startDate;
  public endDate;
  public textSearch = "";
  public loader : boolean = true;

  constructor(private httpService: HttpService, private formContentDataService: FormContentDataService) {
    this.subscriber = this.formContentDataService.formDataSource$.subscribe(res => {
      this.loader = false;
      this.listData = res.data;
      this.displayedColumns = res.displayedColumns ? res.displayedColumns.filter(col => col.fieldName != "Activity") : [];
      this.total = res.total
    })
    this.formContentDataService.getFormContentDataByFormDataQuery(this.componentId, { limit: 10, page: 0 }, this.startDate, this.endDate, this.textSearch)
  }
  filterByDate({ fromDate, toDate }) {
    this.startDate = fromDate
    this.endDate = toDate
    this.loader = true;
    this.formContentDataService.getFormContentDataByFormDataQuery(this.componentId, { limit: 10, page: 0 }, this.startDate, this.endDate, this.textSearch)
}

  ngOnInit() {
  }
  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }
  async exportDataToCSV() {
    const params = {
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.loader = true;
    this.formContentDataService.downloadCsvFormContent(params, this.componentId).subscribe((link: any) => {  //get data from last week in server
      window.open(link,'_blank')
      this.loader = false;
    })
    
  }
  pageChange(event) {
    this.loader = true;
    this.formContentDataService.getFormContentDataByFormDataQuery(this.componentId, { limit: event.pageSize, page: event.pageIndex }, this.startDate, this.endDate, this.textSearch);
  }
  search(textSearch: string) {
    this.textSearch = textSearch;
    this.loader = true;
  }
}
