<nav class="navbar navbar-top navbar-expand navbar-dark bg-icl border-bottom" id="navbar-main">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
       <!-- Search form -->
       <!-- <div [hidden]="!showSearchBar" class="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
            <input class="form-control" placeholder="Search..." type="text" [(ngModel)]="searchValue" (focus)="focus = true"
              (blur)="focus = false"  (keyup)="applyFilter(searchValue)"/>
          </div>
        </div>
        <button type="button" class="close" data-action="search-close"
          data-target="#navbar-search-main" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div> -->
           <!-- Navbar links -->
           <ul class="navbar-nav align-items-center ml-md-auto">
            <li class="nav-item d-xl-none">
              <!-- Sidenav toggler -->
              <div
                class="pr-3 sidenav-toggler sidenav-toggler-dark"
                data-action="sidenav-pin"
                data-target="#sidenav-main"
                (click)="openSidebar()"
              >
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
          </ul>
      <!-- User -->
      <ul class="navbar-nav align-items-center ml-auto ml-md-0">
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <i class="fas fa-user"></i>
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">{{user.name}}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" *dropdownMenu>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-single-02"></i> <span>My profile</span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i> <span>Settings</span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i> <span>Activity</span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/examples/user-profile']" class="dropdown-item">
              <i class="ni ni-support-16"></i> <span>Support</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void()" class="dropdown-item">
              <i class="ni ni-user-run"></i> <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="sidenavOpen === true" class="backdrop d-xl-none" (click)="toggleSidenav()"></div>