// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  httpUrl:"https://icl-farmscape.server.private.icldig.icl-group.com/",
  // httpUrl:"http://localhost:3000/",
  firebaseConfig: {
    apiKey: "AIzaSyDU68uaWUh7GzxYcxKwQJ9aYO2MGIzy9YY",
    authDomain: "icl-farmscape.firebaseapp.com",
    projectId: "icl-farmscape",
    storageBucket: "icl-farmscape.appspot.com",
    messagingSenderId: "342822179940",
    appId: "1:342822179940:web:3c8ea5a861daa34d927b71",
    measurementId: "G-HW5B5TKB2W",
  }
};
export const awsAuth = {
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_kmZ4XtJRZ',
    APP_CLIENT_ID: '6ptuojn3800u3c65mod0spt2bp',
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
