import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchTableService {
  private filteSubject = new Subject<any>();
  public filteSubject$ = this.filteSubject.asObservable();
  constructor() { }
  sendFilterEvent(searchValue){
    this.filteSubject.next(searchValue);
  }
}
