<div class=" main-content">
  <div class=" header bg-gradient-icl py-7">
    <div class=" container">
      <div class=" header-body text-center mb-7">
        <div class=" row justify-content-center">
          <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
            <img src="../../../assets/images/Main-Logo2.png" class="main-logo">
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>

  <div class=" container mt--8 pb-5">
    <div class=" row justify-content-center">
      <div class=" col-lg-5 col-md-7">
        <div class=" card bg-secondary border-0 mb-0">
          <div class=" card-body px-lg-5 py-lg-5" *ngIf="!isForceChangePassword">
            <div class=" text-center text-muted mb-4">
              <small>Sign in with credentials </small>
            </div>

            <form role="form" [formGroup]="loginData">
              <div class="form-group mb-3">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                  </div>
                  <input class="form-control" placeholder="Username" type="text" formControlName="name"
                    (focus)="showErrorMessege = false" />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                  </div>
                  <input class="form-control" placeholder="Password" type="password" formControlName="password"
                    (focus)="showErrorMessege = false" />
                </div>
              </div>
              <div class="custom-control custom-control-alternative custom-checkbox">
                <input class="custom-control-input" id=" customCheckLogin" type="checkbox" />
                <label class="custom-control-label" for=" customCheckLogin">
                  <span>Remember me</span>
                </label>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary bg-icl my-4" (click)="login()">
                  Sign in
                </button>
              </div>
              <div *ngIf="showErrorMessege" class="text-center mb-4 text-danger">
                <b>{{errorMessege}}</b>
              </div>
            </form>
          </div>
          <div class="card-body px-lg-5 py-lg-5" *ngIf="isForceChangePassword">
            <div class=" text-center text-muted mb-4">
              <small>Please select a new password</small>
            </div>

            <form role="form" [formGroup]="changePasswordData">
              <div class="form-group mb-3">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                  </div>
                  <input class="form-control" placeholder="Email" type="email" formControlName="email" (focus)="showErrorMessege = false" />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                  </div>
                  <input class="form-control" placeholder="Old password" type="password" formControlName="old_password"
                    (focus)="showErrorMessege = false" />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                  </div>
                  <input class="form-control" placeholder="New password" type="password" formControlName="new_password"
                    (focus)="showErrorMessege = false" />
                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary bg-icl my-4" (click)="changePassword()">
                  Change Password
                </button>
              </div>
              <div *ngIf="showErrorMessege" class="text-center mb-4 text-danger">
                <b>{{errorMessege}}</b>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <a [routerLink]="['/forgot-password']" class=" text-light">
              <small> Forgot password? </small>
            </a>
          </div>
          <div class="col-6 text-right">
            <!-- <a href="new-account" class="text-light">
              <small>  Create new account  </small>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>