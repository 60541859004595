import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AwsAuthError } from 'src/app/class/aws-entites.model';
import { LoginService } from 'src/app/services/login.service';
import swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-verification-email',
  templateUrl: './verification-email.component.html',
  styleUrls: ['./verification-email.component.scss']
})
export class VerificationEmailComponent implements OnInit {
  public errorMessege:string;
  public showErrorMessege: boolean = false;
  public forgotPasswordVerificationData:FormGroup = new FormGroup({});
  private arrFormBuilderVerification = {};
  public passwordStrengthText: string; //Weak,Medium,Strong
  public passwordStrengthClass: string; // text-danger,text-warning,text-success
  constructor(private fb:FormBuilder,private loginService:LoginService,private router:Router) { 
    this.setControlFieldsVerification();
  }
  setControlFieldsVerification() {
    this.arrFormBuilderVerification = {};
    this.arrFormBuilderVerification["email"] = ['', Validators.required];
    this.arrFormBuilderVerification["new_password"] = ['', Validators.required];
    this.arrFormBuilderVerification["verification_code"] = ['', Validators.required];
    this.forgotPasswordVerificationData = this.fb.group(this.arrFormBuilderVerification);
  }
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
  }
  checkPasswordStrength() {
    let password: string = this.forgotPasswordVerificationData.controls['new_password'].value;
    let pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    //Strong
    if (pattern.test(password)) {
      this.passwordStrengthText = "Strong";
      this.passwordStrengthClass = "text-success";
    }
    //Medium
    // else if (password && password.length >= 8 && (password.search(/[a-z]/i) >= 0 || password.search(/[A-Z]/i) >= 0) && password.search(/[0-9]/) >= 0) {
    //   this.passwordStrengthText = "Medium";
    //   this.passwordStrengthClass = "text-warning";
    // }
    //Weak
    else {
      this.passwordStrengthText = "Weak";
      this.passwordStrengthClass = "text-danger";
    }
  }
  verificationCode(){
    if(this.forgotPasswordVerificationData.valid && this.passwordStrengthText == "Strong"){
      this.loginService.confirmPassword(this.forgotPasswordVerificationData.controls.email.value.toLowerCase(),this.forgotPasswordVerificationData.controls.verification_code.value,this.forgotPasswordVerificationData.controls.new_password.value).then(res=>{
       const self = this;
       swal.fire('Success!', 'Password changed successfully', 'success',function(){
         self.router.navigateByUrl('/login')
       });
       this.router.navigateByUrl('/login');
      }).catch((err: AwsAuthError) => {
          this.errorMessege = err.message;
          this.showErrorMessege = true;
        });
    }
    else{
      if(this.passwordStrengthText != "Strong" && this.forgotPasswordVerificationData.valid ){
        this.errorMessege = "Enter Strong password";
        this.showErrorMessege = true;
      }
      else{
        this.errorMessege = "Enter all fields";
        this.showErrorMessege = true;
      }
    }
  }
}
