import { Injectable } from '@angular/core';
import { Field } from '../class/Field';

@Injectable({
  providedIn: 'root'
})
export class CropMasterService {

  constructor() { }
  getEditCropFocusFields(data){
    return [
      {
        fieldName: "cropFocus",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Crop Focus",
        value: data.cropFocus,
      }
    ]
    }

    getCropFocusFields(){
      return [
        {
          fieldName: "cropFocus",
          type: 0,
          addRow: false,
          isRequired: true,
          placeholder: "Crop Focus",
        }
      ]
      }
}