import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Field } from 'src/app/class/Field';
import { EmployeeMasterService } from 'src/app/services/employee-master.service';
import { ExportService } from 'src/app/services/export.service';
import { HttpService } from 'src/app/services/http.service';
import { PopupComponent } from '../../shared-component/popup/popup.component';
import { LoginService } from 'src/app/services/login.service';
import swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-master',
  templateUrl: './employee-master.component.html',
  styleUrls: ['./employee-master.component.scss']
})
export class EmployeeMasterComponent implements OnInit {
  public tableTitle: string = "Employee Data Retrieval";
  public listData;
  public fileteredData;
  public addNewEmployeeActive: boolean = false;
  public displayedColumns: string[] = ['ClientName', 'FirstName', 'LastName', 'Mobile', 'State','District', 'RegionalOffice', 'Territory', 'Headquarter', 'Email-id', 'Role', 'Status','CreateBy','edit','delete'];
  public newEmployeeFields: Array<Field>;
  public user;
  stateDistrictList: any;

  constructor(private loginService: LoginService, private exportService: ExportService, private dialog: MatDialog, private employeeMasterService: EmployeeMasterService, private http: HttpService) {
    this.user = this.loginService.getCurrentUser();

    this.http.getAllStateDistrict().subscribe((result: any) => {
      const stateDistrictMap = new Map();
      result.forEach(item => {
          const key = item.state;
          if (stateDistrictMap.has(key)) {
              stateDistrictMap.get(key).push(item.district);
          } else {
              stateDistrictMap.set(key, [item.district]);
          }
      });
      let stateDistrictLocation =
      {
        state:Array.from(new Set(result.map(el => `${el.state}`))),
        stateDistrict: Array.from(stateDistrictMap, ([key, value]) => `${key}-${value.join(',')}`),
        districtlocation : result.map(el => `${el.state}-${el.district}-${el["location"].join(',')}`)
      }
      this.stateDistrictList  = stateDistrictLocation;
      this.newEmployeeFields = this.employeeMasterService.getNewEmployeeFields(stateDistrictLocation.stateDistrict,this.stateDistrictList);
    })
    this.getAllUsers();
  }

  ngOnInit() {
  }

  getAllUsers() {
    this.http.getAllUsers().subscribe((result: any) => {
      this.listData = result;
    })
  }

  handleFilteredData(data) {
    console.log(data, "data")
    this.fileteredData = data;
  }

  exportDataToCSV(){
    let fileName: string = this.tableTitle + "-" + moment().format('MM.DD.YYYY').toString();
    if(this.fileteredData) {
      this.exportService.exportExcel(this.fileteredData, fileName);
    } else {
      this.exportService.exportExcel(this.listData, fileName);
    }
  }

  addNewEmployee() {
    var popupData = {
      title: "Add new employee",
      fields: this.newEmployeeFields,
      stateDistrictLocationList: this.stateDistrictList,
      button: "Add",
      type: "user"
    };
    const dialogRef = this.dialog.open(PopupComponent, { data: popupData, panelClass: 'full-width-dialog' });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        data.Status = 'Active',
          data.CreateBy = this.user.name
        // create new employee in DB  -http call + signupInCognito
        try{
          this.http.addUserInCognito(data).subscribe((res: any) => {
          console.log(res);
          if (res.message == "User has been created successfully") {
            swal.fire('Success...', 'User has been created successfully', 'success');
            this.http.addUser(data).subscribe((result: any) => {
              this.getAllUsers();
            });
          } else if (res.response.name == "UsernameExistsException") {
            swal.fire('Oops...', 'This email already exists!', 'error');
          } else {
            swal.fire('Oops...', 'An error occurred, please try again!', 'error');
          }
        })}catch(err){
          swal.fire('Oops...', 'An error occurred, please try again!', 'error');
        }
      }
    })
  }
  updateUser(user){
    this.http.updateUser(user).subscribe(data=>{
      this.http.getAllUsers().subscribe(result=>{
        this.listData = result;
        swal.fire('Updated!', 'Employee has been updated.', 'success');
      })
    })
  }
  deleteUser(user){
     // delete employee from DB  -http call + deleteUserFromCognito
     try{
      this.http.deleteUserFromCognito({'Email-id':user['Email-id']}).subscribe((res: any) => {
      console.log(res);
      if (res.message == "User has been delete successfully") {
        this.http.deleteUser({id:user._id}).subscribe(data=>{
          this.getAllUsers();
            swal.fire('Deleted!', 'Employee has been deleted.', 'success');
        });
      } else {
        swal.fire('Oops...', 'An error occurred, please try again!', 'error');
      }
    })}catch(err){
      swal.fire('Oops...', 'An error occurred, please try again!', 'error');
    }
  }
}
