<div class=" main-content">
    <div class=" header bg-gradient-icl py-7">
        <div class=" container">
            <div class=" header-body text-center mb-7">
                <div class=" row justify-content-center">
                    <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
                        <img src="../../../assets/images/Main-Logo2.png" class="main-logo">
                    </div>
                </div>
            </div>
        </div>

        <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </div>

    <div class=" container mt--8 pb-5">
        <div class=" row justify-content-center">
            <div class=" col-lg-5 col-md-7">
                <div class=" card bg-secondary border-0 mb-0">
                    <div class=" card-body px-lg-5 py-lg-5">
                        <div class=" text-center text-muted mb-4">
                            <small>sign up with credentials</small>
                        </div>

                        <form role="form" [formGroup]="signUpData">
                            <div class="form-group mb-3">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-mobile" aria-hidden="true"></i></span>
                                    </div>
                                    <input class="form-control" placeholder="Mobile number" type="number"
                                        formControlName="mobileNumber" (focus)="showErrorMessege = false" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                                    </div>
                                    <input class="form-control" placeholder="Email" type="email"
                                        formControlName="email" (focus)="showErrorMessege = false" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                                    </div>
                                    <input class="form-control" placeholder="Password" type="password"
                                        formControlName="password" (keydown)="checkPasswordStrength()" (focus)="showErrorMessege = false" />
                                </div>
                            </div>
                            <div class="text-muted font-italic">
                                <small>password strength:
                                    <span class="{{passwordStrengthClass}} font-weight-700" >{{passwordStrengthText}}</span>
                                </small>
                            </div>
                            <div class="row my-4">
                                <div class="col-12">
                                    <div class="custom-control custom-control-alternative custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheckRegister">
                                        <label class="custom-control-label" for="customCheckRegister">
                                            <span>I agree with the <a href="http://icl-group-sustainability.co.il/privacy-policy/">Privacy Policy</a></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="text-center">
                                <button type="button" class="btn btn-primary bg-icl my-4" (click)="signUp()">
                                    Create account
                                </button>
                            </div>
                            <div *ngIf="showErrorMessege" class="text-center mb-4 text-danger">
                                <b>{{errorMessege}}</b>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                    </div>
                    <div class="col-6 text-right">
                        <a href="login" class="text-light">
                            <small> Login </small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>