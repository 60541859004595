import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private loginService:LoginService,private router:Router) { }

  public canActivate(): boolean|Observable<boolean> {
    return new Observable<boolean>((observer) => {
        this.loginService.getRefreshToken().then(() => {
            console.log("get refresh token complete")
            observer.next(true);
            observer.complete();
        })
      });

  }
}
