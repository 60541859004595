import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ExportService } from 'src/app/services/export.service';
import { Field } from 'src/app/class/Field';
import { PopupComponent } from '../../shared-component/popup/popup.component';
import { CatalogMasterService } from 'src/app/services/catalog-master.service';
import { HttpService } from 'src/app/services/http.service';
import * as moment from 'moment';
import swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-catalog-master',
  templateUrl: './catalog-master.component.html',
  styleUrls: ['./catalog-master.component.scss']
})
export class CatalogMasterComponent implements OnInit {
  readonly state: String;
  readonly district: String
  readonly location: [String]

  public tableTitle: string = "Catalog Master Data Retrieval";
  public listData;
  public fileteredData;
  public displayedColumns: string[] = ['State', 'District', 'Location', 'edit', 'delete'];
  public newCatalogFields: Array<Field>;
  stateDistrictLoacationList: any;
  stateDistrictList;

  constructor(private exportService: ExportService, private dialog: MatDialog, private catalogMasterService: CatalogMasterService, private http: HttpService) {
    this.getAllStateDistrict();
  }

  ngOnInit() { }

  getAllStateDistrict() { // TODO: Change to 'State' and 'District' in server and DB  instead of here
    this.http.getAllStateDistrict().subscribe((result: any) => {
      const stateDistrictMap = new Map();
      result.forEach(item => {
        const key = item.state;
        if (stateDistrictMap.has(key)) {
          stateDistrictMap.get(key).push(item.district);
        } else {
          stateDistrictMap.set(key, [item.district]);
        }
      });
      let stateDistrictLocation =
      {
        state: Array.from(new Set(result.map(el => `${el.state}`))),
        stateDistrict: Array.from(stateDistrictMap, ([key, value]) => `${key}-${value.join(',')}`),
        districtlocation: result.map(el => `${el.state}-${el.district}-${el["location"].join(',')}`)
      }
      this.stateDistrictList = stateDistrictLocation;

      this.newCatalogFields = this.catalogMasterService.getNewCatalogFields(this.stateDistrictList);

      this.listData = result.map(({ _id: _id, state: State, district: District, location: Location }) => (
        {
          _id,
          State,
          District,
          Location
        }));
    })
  }

  handleFilteredData(data) {
    console.log(data, "data")
    this.fileteredData = data;
  }

  exportDataToCSV() {
    let finalData;

    if (this.fileteredData) {
      finalData = this.fileteredData;
    } else {
      finalData = this.listData
    }
    const newdata = finalData
    console.log(newdata, 'final data')
    finalData = finalData.map(catalog => {
      if (Array.isArray(catalog.Location)) {
        catalog.Location = catalog.Location.join(',');
      }
      return catalog;
    });

    console.log(finalData, 'final2')
    let fileName: string = this.tableTitle + "-" + moment().format('MM.DD.YYYY').toString();
    this.exportService.exportExcel(finalData, fileName);
  }

  addNew() {
    var popupData = {
      title: "Add new catalog",
      fields: this.newCatalogFields,
      button: "Add",
      type: "catalog"
    };
    const dialogRef = this.dialog.open(PopupComponent, { data: popupData, panelClass: 'full-width-dialog' });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // create new employee in cognito and in DB
        this.http.addStatDistrict(data).subscribe((result: any) => {
          this.getAllStateDistrict();
          swal.fire('Success...', 'State has been created successfully', 'success');
        })
      }
    })
  }
  updateStateDistrict(stateDistrict) {
    this.http.updateStateDistrict(stateDistrict).subscribe(data => {
      this.getAllStateDistrict();
      swal.fire('Updated!', 'State District has been updated.', 'success');

    })
  }
  deleteStateDistrict(stateDistrict) {
    this.http.deleteStateDistrict({ id: stateDistrict._id }).subscribe(data => {
      this.getAllStateDistrict();
      swal.fire('Deleted!', 'State has been deleted.', 'success');
    })
  }
}
