import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import ApiProvider from 'src/cognito-login/src/Services/Api/ApiProvider';
import { Session } from 'src/cognito-login/src/Components/UserProfile/Session';
import { User } from '../class/User';
import { Cognito } from '../enums/cognito';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public cognito_aws = ApiProvider;
  private tokenExpirationTimer: any;
  private isLogin;
  redirectUrl: string;
  user = new BehaviorSubject<User>(null);
  _user: User;
  constructor(private httpHandler: HttpHandlerService, private router: Router) {
    this.refreshToken();
    this.setUserLogin(localStorage.getItem('loginUser')?true:false)
  }

  async getRefreshToken() {
    return new Promise(async(response, rej) => {
      if(this.isUserLogin && localStorage.getItem('loginUser')) {
        let user = JSON.parse(localStorage.getItem('loginUser'))
        const tokenUser = new User("tokenUser", user._token)
        if(new Date() > new Date(tokenUser.getTokenExpirationMilli())) {
          let refreshToken = JSON.parse(localStorage.getItem('loginUser')).refreshToken;
          let res = await this.cognito_aws.api.user.refreshSession(refreshToken);
          try {
            if (res instanceof Session) {
              const session_data = res.getIdToken().split('.');
              const userSession = this.decodeBase64(session_data[1]);
              this.handleAuthentication(userSession.email, res.getIdToken(), userSession.exp, res.getRefreshToken());
              response(1)
            }
          }
          catch (err) {
            console.log(err);
            this.logOut();
            this.router.navigateByUrl('login');
            response(1)
          }
        } else {
          response(1)
        }
        console.log(tokenUser.getTokenExpiration(), tokenUser.getTokenExpirationMilli(), 'token expiration')
      } else {
        response(1)
      }
    })
  }

  signIn(mail: string, pass: string, new_pass?: string, success?: Function, failure?: Function) {
    this.cognito_aws.api.user.login(mail, pass, new_pass? new_pass:'').then(res => {
      if (res instanceof Session) {
        const session_data = res.getIdToken().split('.');
        const userSession = this.decodeBase64(session_data[1]);
        if(userSession && userSession['cognito:groups'] &&  userSession['cognito:groups'].find(cognitoGroup=>cognitoGroup==Cognito.adminGroup)!=undefined){
            this.handleAuthentication(userSession.email, res.getIdToken(), userSession.exp,res.getRefreshToken());
        }
        else {
          this.setUserLogin(false);
        }
        success(res);
      }
      else {
        failure(res);
      }
    }).catch((err) => {
      failure(err);
    });
  }
  signUp(mail: string, pass: string, attributes: any){
    return this.cognito_aws.api.user.register(mail, pass, mail, attributes)
  }
  forgotPassword(userName,verificationCode?,new_pass?) {
    if(verificationCode && new_pass)
      return this.cognito_aws.api.user.forgotPassword(userName,verificationCode,new_pass);
    else return this.cognito_aws.api.user.forgotPassword(userName)
  }
  confirmPassword(userName,verificationCode,new_pass){
    return this.cognito_aws.api.user.confirmPassword(userName,verificationCode,new_pass);
  }
  logOut() {
    localStorage.removeItem('loginUser');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
    this.setUserLogin(false);
    this.router.navigateByUrl('/login')
  }
  decodeBase64(text: any) {
    // decodeURIComponent & escape fixed hebrew letters with atob function
    const decodedText = decodeURIComponent(escape(atob(text)));
    const loginDetails = JSON.parse(decodedText);
    return loginDetails;
  }
  private handleAuthentication(name: string, token: string, exp: number,refreshToken:string) {
    const expirationDate = new Date(exp * 1000).getTime();
    const user = new User(name, token,refreshToken, new Date(expirationDate));
    this._user = user;
    this.user.next(user);
    localStorage.setItem('loginUser', JSON.stringify(user));
    this.setUserLogin(true);
  }
  autoLogOut(expirationDuration: number) {
    const halfHour = 30 * 60 * 1000;
    if (expirationDuration > halfHour) { expirationDuration -= halfHour; }
    if(typeof expirationDuration === 'number' && !isNaN(expirationDuration)){
      this.tokenExpirationTimer = setTimeout(() => {
        this.logOut();
      }, expirationDuration);
    }
    else{
      console.log("auto logout not working well");
    }
  }
  isUserLogin(){
    return this.isLogin;
  }
  setUserLogin(login:boolean){
    this.isLogin = login;
  }
  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }
  getCurrentUser(){
    return JSON.parse(localStorage.getItem('loginUser'));
  }
  getToken(): string {
    if (this._user)
      return this._user.token;
    else return "";
  }
  refreshToken(){
    if(localStorage.getItem('loginUser')){
      let refreshToken = JSON.parse(localStorage.getItem('loginUser')).refreshToken;
      this.cognito_aws.api.user.refreshSession(refreshToken).then(res=>{
        if(res instanceof Session){
          const session_data = res.getIdToken().split('.');
          const userSession = this.decodeBase64(session_data[1]);
          this.handleAuthentication(userSession.email, res.getIdToken(), userSession.exp,res.getRefreshToken());
        }
      }).catch(err=>{
        console.log(err);
      })
    }
    else {
      this.logOut();
      this.router.navigateByUrl('login');
    }
  }
}
