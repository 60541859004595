<div class=" card">
  <div class=" card-header border-0">
    <div class="row align-items-center">
      <h3 class="mb-0 col-lg-4 col-7">{{tableTitle}}</h3>

      <div class="navbar-search navbar-search-light form-inline mr-sm-3 " id="navbar-search-main">
        <div class="form-group mb-0">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
            <input class="form-control" placeholder="Search..." type="text" [(ngModel)]="searchValue"
              (keyup)="applyFilter($event)" />
          </div>
        </div>
        <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main"
          aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

    </div>

  </div>
  <div class=" table-responsive" *ngIf="listData && listData.length>0; else noData">
    <table class="table align-items-center table-flush" mat-table matSort [dataSource]="dataSource">
      <ng-container *ngFor="let displayedCol of displayedColumns" [matColumnDef]="displayedCol">
        <th class="thead-light" scope="col" mat-header-cell *matHeaderCellDef [mat-sort-header]
          [arrowPosition]="before">
          <span *ngIf="displayedCol !== 'edit' && displayedCol !== 'delete'">{{displayedCol}}</span>
        </th>
        <ng-container *ngIf="displayedCol !== 'edit' && displayedCol !== 'delete'">
          <td mat-cell *matCellDef="let element">
            <span class="text-muted">{{element[displayedCol] }}</span>
          </td>
        </ng-container>

        <!-- Action columns -->
        <ng-container *ngIf="displayedCol === 'edit' || displayedCol === 'delete'">
          <!-- <th class="thead-light" mat-header-cell *matHeaderCellDef *ngIf="displayedCol === 'edit' || displayedCol === 'delete'"></th> -->

          <td mat-cell *matCellDef="let element" class=" table-actions">
            <a (click)="openEditPopup(element)" *ngIf="displayedCol === 'edit'">
              <mat-icon class="table-action click-able">edit</mat-icon>
            </a>
            <a (click)="confirmDelete(element)" *ngIf="displayedCol === 'delete'">
              <mat-icon class="table-action table-action-delete click-able">delete</mat-icon>
            </a>
          </td>
        </ng-container>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="resultsLength" [pageSize]="resultsPageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event"></mat-paginator>
  </div>
  <ng-template #noData>
    <mat-divider></mat-divider>
    <mat-label class="no-data-placeholder">
      No Search result found
    </mat-label>
    <mat-divider></mat-divider>
  </ng-template>

</div>