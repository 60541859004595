import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { Config, NET_TYPES } from '../../config/config';

export default class Network {

    /**
     * by default it is all empty fields (from the config file)
     * @param schema http/https
     * @param host local/aws
     * @param stage prod/dev
     * @param port 3006/3007
     */
    private static setNet(schema: string, host: string, stage: string, port: string): void {
        Config.url.schema = schema;
        Config.url.host = host;
        Config.url.stage = stage;
        Config.url.port = port;
    }

    /**
     * create our network instance for http requests: GET, POST, etc.
     */
    public static getAxios(): AxiosInstance {

        // TODO: needs to handle ports
        switch (Config.net.type) {
            case NET_TYPES.AWS:
                this.setNet('https', '0nh1ileld9.execute-api.eu-west-1.amazonaws.com', 'prod', '');
                break;
            case NET_TYPES.LOCAL:
                this.setNet('http', 'localhost', 'prod', '');
                break;
            default:
                throw new Error('no configuration is set!');
        }

        let baseUrl = `${Config.url.schema}://${Config.url.host}/${Config.url.stage}`;

        if (Config.url.port) {
            baseUrl += `:${Config.url.port}`;
        }

        let config: AxiosRequestConfig = {
            baseURL: baseUrl,
            timeout: 2000
        };

        return axios.create(config);
    }
}