/**
 * A class for a user's basic information
 */

export class User {
    [index: string] : any
    private name: string; // The user's username
    private email!: string;
    private given_name!: string;
    private family_name!: string;
    private gender!: string;
    private birthdate!: Date;
    private custom_city!: string;
    private custom_country!: string;
    private custom_about!: string; // The user's "about me" text
    private User_Link!:string

    constructor(name: string) {
        this.name = name;
    }

    getUserName = (): string => {
        return this.name;
    }

    getEmail = (): string => {
        return this.email;
    }

    getFirstName = (): string => {
        return this.given_name;
    }

    getLastName = (): string => {
        return this.family_name;
    }

    getGender = (): string => {
        return this.gender;
    }

    getBirthdate = (): Date => {
        return new Date(this.birthdate);
    }

    getCity = (): string => {
        return this.custom_city;
    }

    getCountry = (): string => {
        return this.custom_country;
    }

    getAbout = (): string => {
        return this.custom_about;
    }
    getUserLink = ():string =>{
        return this.User_Link;
    }

    setEmail = (email: string): User => {
        this.email = email;
        return this;
    }

    setFirstName = (givenName: string): User => {
        this.given_name = givenName;
        return this;
    }

    setLastName = (familyName: string): User => {
        this.family_name = familyName;
        return this;
    }

    setGender = (gender: string): User => {
        this.gender = gender;
        return this;
    }

    setBirthdate = (birthdate: Date): User => {
        this.birthdate = birthdate;
        return this;
    }

    setCountry = (country: string): User => {
        this.custom_country = country;
        return this;
    }

    setCity = (city: string): User => {
        this.custom_city = city;
        return this;
    }

    setAbout = (about: string): User => {
        this.custom_about = about;
        return this;
    }
    setUserLink = (UserLink: string): User => {
        this.User_Link = UserLink;
        return this;
    }
}