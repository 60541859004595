import { Component, OnInit, Input, ViewChild, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import * as moment from 'moment'
import { ExportService } from 'src/app/services/export.service';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { SearchTableService } from 'src/app/services/search-table.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSort } from '@angular/material';
import { DatePipe } from '@angular/common';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-search-table',
  templateUrl: './search-table.component.html',
  styleUrls: ['./search-table.component.scss'],
  host: { class: 'width-components' }
})
export class SearchTableComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() listData;
  @Input() tableTitle;
  @Input() displayedColumns: any[];
  @Input() showFilter: boolean = false;
  @Input() total:number;
  @Input() loader:boolean;
  @Output() dataFiltered = new EventEmitter<{ fromDate: Date, toDate: Date }>()
  @Output() pageChange = new EventEmitter<any>();
  @Output() searchEvent = new EventEmitter<string>();
  public dataSource;
  public resultsLength: number = 0;
  public resultsPageSize: number = 10;
  public pageEvent;
  public pageSizeOptions;
  public currentPage: number = 0;
  private subscriber;
  public pipe: DatePipe;
  public searchValue = '';

  public range = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl()
  });
  colNames: any;

  public get fromDate() { return this.range.get('fromDate').value; }
  public get toDate() { return this.range.get('toDate').value; }
  
  public set fromDate(fromDate: Date) {
    this.range.get('fromDate').setValue(fromDate)
  }
  public set toDate(toDate: Date) {
    this.range.get('toDate').setValue(toDate)
  }



  constructor(private httpService: HttpService, private dialog: MatDialog, private exportService: ExportService, private router: Router, private searchTableService: SearchTableService) {
    this.dataSource = new MatTableDataSource<any>(this.listData);
    this.dataSource.paginator = this.paginator;

    this.listData ? this.resultsLength = this.listData.length : 0;

    this.pipe = new DatePipe('en');

    this.dataSource.filterPredicate = (data, filter) => {
      return ((Object.keys(data)
        .filter(key => key == filter.key))
        .findIndex(filteredKey => data[filteredKey].indexOf(filter.value) !== -1) !== -1)
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["listData"]) {
      if(this.listData[0]){
        this.listData = this.listData.map(l => { return { ...l, createdDate: new Date(l.createdDate).toLocaleDateString('it'), CreateBy: "harel hazan" } })
        this.dataSource = new MatTableDataSource<any>(this.listData); 
        this.dataSource.paginator = this.paginator;
        this.listData ? this.resultsLength = this.listData.length : 0;
        this.colNames = this.displayedColumns.map(col=>col.fieldName);
        this.dataSource.paginator ?  this.dataSource.paginator.pageIndex = this.currentPage:'';
        this.dataSource.paginator ? this.dataSource.paginator.length = this.total:'';
      }
    }
  }

  convertToDate(date: string): Date {
    return date ? moment(date, 'dd/MM/yyyy').toDate() : null;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
  }
  resetDateFilter() {
    var d = new Date();
    this.fromDate = new Date(d.setMonth(d.getMonth() - 1)); //1 month ago
    this.toDate = new Date();
    this.dataFiltered.emit({ fromDate: this.fromDate, toDate: this.toDate })
  }
  applyDateFilter() {
    this.dataFiltered.emit({ fromDate: this.fromDate, toDate: this.toDate })

  }

  applyFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.searchEvent.emit(this.searchValue.trim());
    this.dataSource.filter = this.searchValue.trim();
    this.dataFiltered.emit({ fromDate: this.fromDate, toDate: this.toDate })

  }

  getImageContent(image){
    let content;
    switch(image.type){
      case "image/png":{
        content = 'data:image/png;base64,' + image.content
        break;
      }
      case "image/jpeg":{
        content = 'data:image/jpeg;base64,' + image.content;
        break;
      }
      case "application/pdf":{
        content = 'data:application/pdf;base64,' + image.content;
        break;
      }
      default:{
        content = 'data:image/png;base64,' + image.content
      }
    }
    return content;
  }
  async pageChangedEvent(event){
    // pageSize: number, currentPage: number
    this.currentPage = event.pageIndex;
    this.pageChange.emit(event);
  }

}
