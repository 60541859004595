import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ExportService } from 'src/app/services/export.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-village-master',
  templateUrl: './village-master.component.html',
  styleUrls: ['./village-master.component.scss']
})
export class VillageMasterComponent implements OnInit {
  public tableTitle: string = "Village Details Retrieval";
  public listData;
  public displayedColumns = ['date', 'villageName', 'createdBy'];
  public fileteredData;
  constructor(private exportService: ExportService, private httpService: HttpService) {
    this.httpService.getAllFormsContent().subscribe((res: any) => {
      this.listData = res;
    })
  }

  ngOnInit() {

  }

  handleFilteredData(data) {
    console.log(data, "data")
    this.fileteredData = data;
  }

  exportDataToCSV() {
    let fileName: string = this.tableTitle + "-" + moment().format('MM.DD.YYYY').toString();
    if(this.fileteredData) {
      this.exportService.exportExcel(this.fileteredData, fileName);
    } else {
      this.exportService.exportExcel(this.listData, fileName);
    }
  }

}
