import { Injectable } from '@angular/core';
import firebase from 'firebase'
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }
  init(){
    const app = firebase.initializeApp(environment.firebaseConfig);
    firebase.analytics();
  }
}
