<app-header [showSearchBar]="true"></app-header>
<div class=" header header-dark bg-icl pb-6 content__title content__title--calendar">
    <div class=" container-fluid">
        <div class=" header-body">
            <div class=" row align-items-center py-4">
                <div class=" col-lg-6 col-7">
                    <h6 class="h2 text-green-farmScape d-inline-block mb-0"> Mandi Campaign Report</h6>

                    <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
                        <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
                            <li class=" breadcrumb-item">
                                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
                            </li>

                            <li class=" breadcrumb-item">
                                <a href="javascript:void(0)"> Activity reports </a>
                            </li>

                            <li aria-current="page" class=" breadcrumb-item active">
                                Mandi campaign report 
                            </li>
                        </ol>
                    </nav>
                </div>

                <div class=" col-lg-6 col-5 text-right">
                    <a class=" btn btn-sm btn-neutral color-icl" [class.disabled]="listData.length > 0 ? null: true" href="javascript:void(0)" (click)="exportDataToCSV()"> Export </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" container-fluid mt--6">
    <div class=" row">
        <div class=" col">
            <!-- <div *ngIf="showAlertSuccess" class="alert alert-success alert-dismissible fade show" role="alert">
                <span class="alert-inner--text"><strong>{{successMessegeAlert}}</strong> </span>
                <button (click)="showAlertSuccess=!showAlertSuccess" type="button" class="close" data-dismiss="alert"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div *ngIf="showAlertError" class="alert alert-danger alert-dismissible fade show" role="alert">
                <span class="alert-inner--text"><strong>{{errorMessegeAlert}}</strong> </span>
                <button (click)="showAlertError=!showAlertError" type="button" class="close" data-dismiss="alert"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div> -->
            <app-search-table *ngIf="listData"  [loader]="loader" [listData]="listData" [total]="total" (dataFiltered)="filterByDate($event)" [displayedColumns]="displayedColumns" [tableTitle]="tableTitle" (saveChangeData)="updateProject($event)" (delete)="deleteProject($event)" (addClick)="openAddPopup()" (pageChange)="pageChange($event)" (searchEvent)="search($event)"></app-search-table>
        </div>
    </div>
</div>