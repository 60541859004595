import {awsAuth} from "../../../environments/environment"
export enum API_TYPES {
    LB,
    HARDCODED,
    AWS
}


export enum NET_TYPES {
    LOCAL,
    AWS
}

export class Config {
    static userPoolId : string = awsAuth.cognito.USER_POOL_ID;
    static clientId: string = awsAuth.cognito.APP_CLIENT_ID;
    static api: {
        type: API_TYPES 
    } = {
        type: API_TYPES.HARDCODED
    }
    static net: {
        type: NET_TYPES
    } = {
        type: NET_TYPES.AWS
    }

    static url: {
        schema: string,
        host: string,
        port: string,
        stage: string
    } = {
            schema: '',
            host: '',
            port: '',
            stage: ''
        };
}
