import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AwsAuthError } from 'src/app/class/aws-entites.model';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnInit {
  private arrFormBuilder = {};
  public signUpData: FormGroup = new FormGroup({});
  public isLoading = false;
  public showErrorMessege:boolean = false;
  public errorMessege: string = null;
  public passwordStrengthText: string; //Weak,Medium,Strong
  public passwordStrengthClass: string; // text-danger,text-warning,text-success
  constructor(private fb: FormBuilder, private loginService: LoginService, private router: Router  ) {
    this.setControlFields();
  }
  setControlFields() {
    this.arrFormBuilder = {};
    this.arrFormBuilder["mobileNumber"] = ['', Validators.required];
    this.arrFormBuilder["email"] = ['',Validators.required ];
    this.arrFormBuilder["password"] = ['', Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,}$/)];
    this.signUpData = this.fb.group(this.arrFormBuilder);
  }
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
  }
  checkPasswordStrength() {
    let password: string = this.signUpData.controls['password'].value;
    let pattern = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,}$/;
    //Strong
    if (pattern.test(password)) {
      this.passwordStrengthText = "Strong";
      this.passwordStrengthClass = "text-success";
    }
    //Medium
    else if (password && password.length >= 8 && (password.search(/[a-z]/i) >= 0 || password.search(/[A-Z]/i) >= 0) && password.search(/[0-9]/) >= 0) {
      this.passwordStrengthText = "Medium";
      this.passwordStrengthClass = "text-warning";
    }
    //Weak
    else {
      this.passwordStrengthText = "Weak";
      this.passwordStrengthClass = "text-danger";
    }
  }
  
  signUp() {
    if (this.signUpData.valid) {
      this.showErrorMessege = false;
      // , { "custom:phone_number": this.signUpData.controls['mobileNumber'].value }
      this.loginService.signUp(this.signUpData.controls['email'].value.toLowerCase(), this.signUpData.controls['password'].value,{}).then(res => {
        this.isLoading = false;
        this.errorMessege = null;
        this.router.navigate(['/promo-activity-report']);
        this.signUpData.reset();
      }).catch((err: AwsAuthError) => {
        this.errorMessege = err.message;
        this.showErrorMessege = true;
        this.isLoading = false;
      });
    }
    else {
      if(!this.signUpData.controls["password"].valid && this.signUpData.controls['mobileNumber'].valid && this.signUpData.controls['email'].valid){
        this.errorMessege = "Please enter a strong password";
        this.showErrorMessege = true;
      }
      else {
        this.errorMessege = "Please fill in all fields";
        this.showErrorMessege = true;
      }
    }

  }
}
