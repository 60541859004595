
import Apiable from "./Apiable";
import { Config, API_TYPES } from "../../config/config";
import AwsApi from "./AwsApi";

export default class ApiProvider {

    static get api(): Apiable {

        let apires = new AwsApi();

        switch (Config.api.type) {
            case API_TYPES.LB:
                // apires = new LbApi();
                break;
            case API_TYPES.HARDCODED:
                // apires = new ApiMockable();
                break;
            case API_TYPES.AWS:
                apires = new AwsApi();
                break;
        }

        return apires;
    }
}