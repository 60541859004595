import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormContentDataService } from 'src/app/services/form-content-data.service';

@Component({
    selector: 'app-promo-activity-report',
    templateUrl: './promo-activity-report.component.html',
    styleUrls: ['./promo-activity-report.component.scss']
})
export class PromoActivityReportComponent implements OnInit, OnDestroy {
    public tableTitle: string = "Promotional Activities Report";
    public listData = [];
    public displayedColumns: string[];
    public total: number;
    public componentId = 1;
    private subscriber;
    public startDate;
    public endDate;
    public textSearch: string = "";
    public loader: boolean = true;
    constructor(private formContentDataService: FormContentDataService) {

        this.subscriber = this.formContentDataService.formDataSource$.subscribe(res => {
            this.loader = false;
            this.listData = res.data;
            this.displayedColumns = res.displayedColumns;
            this.total = res.total
        })
        this.formContentDataService.getFormContentDataByFormDataQuery(this.componentId, { limit: 10, page: 0 }, this.startDate, this.endDate, this.textSearch)
    }


    ngOnDestroy() {
        this.subscriber.unsubscribe();
    }
    ngOnInit() {
        var body = document.getElementsByTagName("body")[0];
        body.classList.remove("bg-default");
    }
    
    filterByDate({ fromDate, toDate }) {
        this.startDate = fromDate
        this.endDate = toDate
        this.loader = true;
        this.formContentDataService.getFormContentDataByFormDataQuery(this.componentId, { limit: 10, page: 0 }, this.startDate, this.endDate, this.textSearch)
    }

    async exportDataToCSV() {
        const params = {
            startDate: this.startDate,
            endDate: this.endDate,
            textSearch: this.textSearch
        }
        this.formContentDataService.downloadCsvFormContent(params, this.componentId).subscribe((link: string) => {  //get data from last week in server
            window.open(link, '_self')
        })
    }
    pageChange(event) {
        this.loader = true;
        this.formContentDataService.getFormContentDataByFormDataQuery(this.componentId, { limit: event.pageSize, page: event.pageIndex }, this.startDate, this.endDate, this.textSearch);
    }
    search(textSearch: string) {
        this.textSearch = textSearch;
        this.loader = true;
    }
}
