import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-data-set-master',
  templateUrl: './data-set-master.component.html',
  styleUrls: ['./data-set-master.component.scss']
})
export class DataSetMasterComponent implements OnInit {
  public tableTitle:string = "Data Set Master Data Retrieval";
  public displayedColumns = [];
  public listData;
  constructor(private exportService:ExportService) { }

  ngOnInit() {
  }
  exportDataToCSV(){
    let fileName: string = this.tableTitle + "-" + moment().format('MM.DD.YYYY').toString();
    this.exportService.exportExcel(this.listData, fileName);
  }
}
