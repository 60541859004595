import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ExportService } from 'src/app/services/export.service';
import { Field } from 'src/app/class/Field';
import { PopupComponent } from '../../shared-component/popup/popup.component';
import { CatalogMasterService } from 'src/app/services/catalog-master.service';
import { HttpService } from 'src/app/services/http.service';
import * as moment from 'moment';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { CropMasterService } from 'src/app/services/crop-master.service';

@Component({
  selector: 'app-crop-focus-master',
  templateUrl: './crop-focus-master.component.html',
  styleUrls: ['./crop-focus-master.component.scss']
})
export class CropFocusMasterComponent implements OnInit {
  readonly state: String;
  readonly district: String
  readonly location: [String]

  public tableTitle: string = "Catalog Master Data Retrieval";
  public listData;
  public fileteredData;
  public displayedColumns: string[] = ['cropFocus','edit', 'delete'];
  public newCropFields: Array<any>;
  stateDistrictLoacationList: any;
  stateDistrictList;
  crops: any;

  constructor(private exportService: ExportService, private dialog: MatDialog, private cropMasterService: CropMasterService, private http: HttpService) {
    this.getAllCrops();
  }

  ngOnInit() { }

  getAllCrops() { 
    this.http.getAllCrops().subscribe((response: any) => {
      this.listData = response.crops; 
      this.newCropFields=this.cropMasterService.getCropFocusFields()
    });
  }

  handleFilteredData(data) {
    console.log(data, "data")
    this.fileteredData = data;
  }

  exportDataToCSV() {
    let finalData;

    if (this.fileteredData) {
      finalData = this.fileteredData;
    } else {
      finalData = this.listData
    }
    const newdata = finalData
    console.log(newdata, 'final data')
    finalData = finalData.map(catalog => {
      if (Array.isArray(catalog.Location)) {
        catalog.Location = catalog.Location.join(',');
      }
      return catalog;
    });

    console.log(finalData, 'final2')
    let fileName: string = this.tableTitle + "-" + moment().format('MM.DD.YYYY').toString();
    this.exportService.exportExcel(finalData, fileName);
  }

  addNew() {
    var popupData = {
      title: "Add new catalog",
      fields: this.newCropFields,
      button: "Add",
      type: "Crop Focus"
    };
    const dialogRef = this.dialog.open(PopupComponent, { data: popupData, panelClass: 'full-width-dialog' });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // create new employee in cognito and in DB
        this.http.addCropFocus(data).subscribe((result: any) => {
          this.getAllCrops();
          swal.fire('Success...', 'Crop Focus has been created successfully', 'success');
        })
      }
    })
  }
  updateCropFocus(data) {
    this.http.updateCropFocus(data).subscribe(data => {
      this.getAllCrops();
      swal.fire('Updated!', 'Crop Focus has been updated.', 'success');

    })
  }
  deleteCropFocus(stateDistrict) {
    this.http.deleteCropFocus(stateDistrict).subscribe(data => {
      this.getAllCrops();
      swal.fire('Deleted!', 'State has been deleted.', 'success');
    })
  }
}
