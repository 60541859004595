import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.csv';
  private exportActivityReportSource = new Subject<any>();
  public exportActivityReportSource$ = this.exportActivityReportSource.asObservable();
  private exportMasterSource = new Subject<any>();
  public exportMasterSource$ = this.exportMasterSource.asObservable();
  
  public exportExcel(jsonData: any[] = [], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    ws["!cols"] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'], Workbook: { Views: [{ RTL: false }] } };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  public saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
  exportActivityReport(fileName){
    this.exportActivityReportSource.next(fileName);
  }
  exportMasters(fileName){
    this.exportMasterSource.next(fileName);
  }

  
}
