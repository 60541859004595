//const MasterColumns = ['Sr.No', 'Client', 'Activity', 'FA Name', 'Date of Activity', 'Crop Focus', 'District', 'Village', 'Expenses', 'Focus Product', 'Attachments', 'Farmer Name', 'Retailer Firm Name', 'Description', 'Upload Status'];
const PromoColumns = ['Sr No.', 'createdDate', "createdBy",'Client', 'Activity', 'FA Name', 'FA Mobile', 'Date of Activity',  'Crop Category', 'Crop Focus', 'District', 'Village', 'Expenses', 'Focus Product', 'Attachments', 'Meeting Purpose', 'No. of farmers', 'Observations','Observations files', 'Crop Stage', 'Farmer Name', 'Farmer Mobile', 'Acres', 'Retailer Firm Name', 'Description','Description Files', 'Instructions/Dose', 'Problem Category', 'Problem Subcategory', 'Problem Description', 'Recommendation', 'Next Visit Date', 'Upload Status'];
const MandiColumns = ['Sr No.', 'createdDate', "createdBy", 'Client Name', 'FA Name', 'FA Mobile', 'Date of Activity', 'Mandi Name', 'District', 'Expenses', 'Crop Category', 'Crop Focus', "Meeting Purpose", 'Product Focus', 'Campaign Purpose', 'Attachments', 'Activity Summary','Activity Summary Files', 'Retailer Firm Name',  'Upload Status']
const L3Columns =    ['Sr No.',	'createdDate', "createdBy",'Client Name',	'FA Name',	'FA Mobile',	'Date of Activity',	'Village',	'District',	'Farmer Name',	'Farmer Mobile',	'Farmer Land(acres)',	'Retailer Firm Name',	'Protocol Date',	'Expenses',	'Crop Category',	'Crop Focus',	'Product Focus',	'Demo Purpose', 'Attachments', 	'Execution Date',	'Dose(Kg/Acres)',	'Demo Area',	'Interim Date',	'Interim',	'Yield Date',	'Yield',		'Current Stage',	'Upload Status']
const VillageColumns = ['date', 'District', 'Village', 'createdBy']
const formIds = {
  'Mandi_Campaign': '60c70d4e222b1754a25cfd96',
  'Demo_L3': '60c73d07222b1754a4235be1',
  'Individual_Farmer_Contact': '60c60244222b1754a25cfd95',
  'Diagnostic_Visit': '60c215719685e051f43ba32c',
  'Field_Day': '60c213739685e051f43ba30c',
  'Farmer_Meeting': '60c2120d9685e051f43ba2ef'
}

export abstract class FormsDetails {
  static readonly definitions = [
    {
      componentId: 1, name: 'Promo activity',
      relatedFormIds: [formIds.Individual_Farmer_Contact, formIds.Diagnostic_Visit, formIds.Field_Day, formIds.Farmer_Meeting], displayedColumns: PromoColumns
    },
    { componentId: 2, name: 'Mandi Campaign', relatedFormIds: [formIds.Mandi_Campaign], displayedColumns: MandiColumns },
    { componentId: 3, name: 'Demo L3', relatedFormIds: [formIds.Demo_L3], displayedColumns: L3Columns },
    {
      componentId: 6, name: 'Village Master',
      relatedFormIds: [formIds.Individual_Farmer_Contact, formIds.Diagnostic_Visit, formIds.Field_Day, formIds.Farmer_Meeting, formIds.Demo_L3],
      displayedColumns: VillageColumns
    }
  ]
}