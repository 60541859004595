<nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()" (mouseout)="onMouseLeaveSidenav()">
  <!-- <perfect-scrollbar> -->
    <div class="scrollbar-inner">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand wrap-img-logo" [routerLink]="['/dashboards/dashboard']">
          <img src="assets/images/menu-logo.png" class="navbar-brand-img" alt="..." />
        </a>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-none d-xl-block " data-action="sidenav-unpin" data-target="#sidenav-main"
            (click)="minimizeSidebar()">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-inner">
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Collapse header -->
          <ul class="navbar-nav">
            <li *ngFor="let menuitem of menuItems" class="nav-item">
              <!--If is a single link-->
              <a routerLinkActive="active" [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'"
                class="nav-link">
                <i class="ni {{ menuitem.icontype }}"></i>
                <span class="nav-link-text">{{ menuitem.title }}</span>
              </a>
              <!--If it have a submenu-->
              <a data-toggle="collapse" routerLinkActive="active" *ngIf="menuitem.type === 'sub'"
                (click)="menuitem.isCollapsed = !menuitem.isCollapsed" [attr.aria-expanded]="!menuitem.isCollapsed"
                [attr.aria-controls]="menuitem.collapse" class="nav-link">
                <i class="ni {{ menuitem.icontype }}"></i>
                <span class="nav-link-text">{{ menuitem.title }}</span>
              </a>

              <!--Display the submenu items-->
              <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'"
                [collapse]="menuitem.isCollapsed" [isAnimated]="true">
                <ul class="nav nav-sm flex-column">
                  <li *ngFor="let childitems of menuitem.children" class="nav-item">
                    <!--If is a single link-->
                    <a routerLinkActive="active" [routerLink]="[childitems.path]" class="nav-link"
                      *ngIf="childitems.type === 'link'">
                      {{ childitems.title }}
                    </a>
                    <!--If it have a submenu-->
                    <a data-toggle="collapse" (click)="childitems.isCollapsed = !childitems.isCollapsed"
                      [attr.aria-expanded]="!childitems.isCollapsed" [attr.aria-controls]="childitems.collapse"
                      *ngIf="childitems.type === 'sub'" class="nav-link">
                      {{ childitems.title }}
                    </a>
                    <!--Display the submenu items-->
                    <div id="{{ childitems.collapse }}" class="collapse" *ngIf="childitems.type === 'sub'"
                      [collapse]="childitems.isCollapsed" [isAnimated]="true">
                      <ul class="nav">
                        <li *ngFor="let childitem of childitems.children" class="nav-item">
                          <a href="{{childitem.path}}" class="nav-link">
                            {{ childitem.title }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- Logout -->
            <li class="nav-item">
              <a [routerLink]="['./login']" [routerLinkActive]="['active']" class="nav-link" (click)="logOut()">
                <i class="fas fa-sign-out-alt color-icl"></i>
                <span class="nav-link-text">LogOut</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  <!-- </perfect-scrollbar> -->

</nav>