/**
 * a generic class to with deal all the errors in our system
 * @param statusCode is the code that we decide to sign
 * @param internalMessage is the message that returns from the API (not shown to the user)
 * @param message is the user error massage
 */
export default class ErrorLogic {

    private statusCode: number;
    private name: string;
    private internalMessage: string;
    private message: string;

    constructor(errType: string, internalMessage: string) {

        this.message = '';
        this.name = errType;
        this.statusCode = this.matchStatusCode(errType);
        this.internalMessage = internalMessage;
    }

    /**
     * 
     * @param errType comes from 'https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html'
     */
    private matchStatusCode(errType: string): number {

        switch (errType) {

            /**
             * Validation
             */
            case 'InvalidParameterException':
                this.message = 'Validation: some (1 or more) fields are not full or not satisfying their constraints';
                return 0;

            /**
             * Username
             */
            case 'UsernameExistsException':
                this.message = 'An account with the given email already exists.';
                return 1;

            /**
             * Password
             */
            case 'InvalidPasswordException':
                this.message = 'Password: password not satisfying constraints';
                return 2;

            /**
             * Username/Password
             */
            case 'NotAuthorizedException':
                this.message = 'User is not authorized to perform this action';
                return 3;

            /**
             * NotFound
             */
            case 'UserNotFoundException':
                this.message = 'NotFound: user not found or not authorized';
                return 4;

            /**
             * Network Error
             */
            case undefined:
                this.name = 'Network Error';
                this.message = 'Network Error: an error network occurred';
                return 998;

            /**
             * Unknown (or Unwritten)
             */
            default:
                this.message = 'Unknown: an unknown error';
                return 999;
        }
    }

    public getStatusCode(): number {
        return this.statusCode;
    }

    public getInternalMessage(): string {
        return this.internalMessage;
    }

    public getMessage(): string {
        return this.message;
    }
}