import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { NavigationEnd } from '@angular/router';
import { SearchTableComponent } from '../search-table/search-table.component';
import { SearchTableService } from 'src/app/services/search-table.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: { class: 'width-components' }
})
export class HeaderComponent implements OnInit {
  @Input()title:string ="";
  @Input()showSearchBar;
  public user;
  public sidenavOpen: boolean = true;
  public focus:boolean = false;
  public searchValue:string = "";
  constructor(private loginService:LoginService,  private searchTableService:SearchTableService) {
    this.user = this.loginService.getCurrentUser();
    if (event instanceof NavigationEnd) {
      if (window.innerWidth < 1200) {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
        this.sidenavOpen = false;
      }
  }
   }

  ngOnInit() {
  }
  openSidebar(){
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  applyFilter(searchValue){
    this.searchTableService.sendFilterEvent(searchValue);
  }
}
