import { UserProfileable } from '../Components/UserProfile/UserProfileable';
import { User } from '../Components/UserProfile/User';
import * as AWSCognito from 'amazon-cognito-identity-js';
import ErrorLogic from './Api/Errors/ErrorLogic';
// import dateFormat from "dateformat"

export class CognitoUserProfile implements UserProfileable {

    userPool: AWSCognito.CognitoUserPool;
    readonly cognitoFormat: string = "COGNITO_FORMAT";
    readonly appFormat: string = "APP_FORMAT";

    constructor(settings: AWSCognito.ICognitoUserPoolData) {
        this.userPool = new AWSCognito.CognitoUserPool(settings);
    }

    /**
     * an interface that describes what returns from the AWS API
     * @param code is the error message code
     * @param message is the message that describes why there is an error
     * @param name is the error name (by the AWS)
     */
    private convertToErrorLogic(error: Error | undefined): ErrorLogic {
        if (error) {
            return new ErrorLogic(error.name, error.message);
        }
        return new ErrorLogic("undefined", "undefined");
    }

    getCurrentUser(): Promise<User | null> {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err: any, session: any) => {
                    if (!err && session) {
                        cognitoUser.getUserAttributes((err, attributes) => {
                            if (!err && attributes) {
                                resolve(this.buildUserObject(cognitoUser, attributes));
                            } else {
                                console.error(err);
                                resolve(null);
                            }
                        });
                    } else {
                        console.error(err);
                        resolve(null);
                    }
                });
            } else {
                // If there is no logged-in user - resolve with null
                resolve(null);
            }
        });
    }

    updateUserAttributes(updatedUser: User): Promise<User | ErrorLogic> {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.userPool.getCurrentUser();

            if (cognitoUser) {
                cognitoUser.getSession((err: any, session: any) => {
                    if (!err) {
                        let cognitoUserAttributes: any[] = this.toAttributesArray(updatedUser);
                        cognitoUser.updateAttributes(cognitoUserAttributes, (err, result) => {
                            if (!err) {
                                resolve(updatedUser);
                            } else {
                                reject(this.convertToErrorLogic(err));
                            }
                        });
                    } else {
                        reject(this.convertToErrorLogic(err));
                    }
                });
            } else {
                reject("No logged-in user");
            }
        });
    }

    private toAttributesArray = (user: User): AWSCognito.CognitoUserAttribute[] => {
        let cognitoUserAttributes: any[] = [];
        for (let key in user) {
            if (user.hasOwnProperty(key) && user[key] && typeof user[key] !== 'function') {
                let val = user[key]
                if (key == "birthdate") {
                    val = this.dateToCognitoDateField(user.getBirthdate())
                }
                cognitoUserAttributes.push(
                    new AWSCognito.CognitoUserAttribute({
                        Name: this.getAttributeNameByFormat(key, this.cognitoFormat),
                        Value: val
                    })
                );
            }
        }
        return cognitoUserAttributes;
    }

    private dateToCognitoDateField(date: Date) {
        // return dateFormat(date, "yyyy-mm-dd");

    }

    private buildUserObject = (
        cognitoUser: AWSCognito.CognitoUser,
        attributes: AWSCognito.CognitoUserAttribute[]
    ): User => {
        let user = new User(cognitoUser.getUsername());
        let currentName, currentValue;
        attributes.forEach(attribute => {
            currentName = this.getAttributeNameByFormat(attribute.getName(), this.appFormat);
            currentValue = attribute.getValue();

            if (user.hasOwnProperty(currentName)) {
                user[currentName] = currentValue;
            }
        });

        return user;
    }

    private getAttributeNameByFormat = (name: string, format: string): string => {

        switch (format) {
            case this.cognitoFormat:
                return name.includes('custom') ? 'custom:' + name.split('custom_')[1] : name;
            case this.appFormat:
                return name.includes('custom') ? 'custom_' + name.split('custom:')[1] : name;
            default:
                return name;
        }
    }
}