import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';
import { Router,Event, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { FirebaseService } from './services/firebase.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'managment-system';
  isMobileResolution: boolean;

  constructor(private loginService:LoginService,private router: Router,private firebaseService:FirebaseService){
    this.firebaseService.init();
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          window.scrollTo(0,0);
      }

      if (event instanceof NavigationEnd) {
          // Hide loading indicator
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }
  });
  }
  async ngOnInit() {
  }

  isLogin(){
    return this.loginService.isUserLogin();
  }
  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
}
