import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ExportService } from 'src/app/services/export.service';
import { FormContentDataService } from 'src/app/services/form-content-data.service';

@Component({
  selector: 'app-demo-l3-report',
  templateUrl: './demo-l3-report.component.html',
  styleUrls: ['./demo-l3-report.component.scss']
})
export class DemoL3ReportComponent implements OnInit, OnDestroy {
  public tableTitle: string = "Demo L3 Report";
  public listData = [];
  public displayedColumns: string[];
  public total: number;
  private componentId = 3;
  private subscriber;
  public startDate;
  public endDate;
  public textSearch = "";
  public loader : boolean = true;

  constructor(private formContentDataService: FormContentDataService) {
    this.subscriber = this.formContentDataService.formDataSource$.subscribe(res => {
      this.loader = false;
      this.listData = this.spreadStateDistrictField(res.data);
      this.displayedColumns = res.displayedColumns ? res.displayedColumns.filter(col => col.fieldName != "Activity") : [];
      this.total = res.total
    })
    this.formContentDataService.getDemoL3FormContentByFormDataQuery({ limit: 10, page: 0 });
  }

  spreadStateDistrictField(data: []) {
    if(data.length == 0 ) return data;
    data.map( (el : object) => {
      const stateDistrict = el["State District"];
      el["State"]  = `${stateDistrict}`.split(",")[0].trim()
      el["District"] = `${stateDistrict}`.split(",")[1].trim()
    })
    return data;
  }

  filterByDate({ fromDate, toDate }) {
    this.startDate = fromDate
    this.endDate = toDate
    this.loader = true;
    this.formContentDataService.getDemoL3FormContentByFormDataQuery({ limit: 10, page: 0 }, this.startDate, this.endDate, this.textSearch)
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.subscriber.unsubscribe();

  }

  async exportDataToCSV() {
    this.loader = true;
    const params = {
        startDate: this.startDate,
        endDate :this.endDate,
        textSearch: this.textSearch
    }
    this.formContentDataService.downloadCsvDemoL3(params).subscribe((link: any) => {  //get data from last week in server
      window.open(link,'_blank')
      this.loader = false;
    })

}
  pageChange(event) {
    this.loader = true;
    this.formContentDataService.getDemoL3FormContentByFormDataQuery({ limit: event.pageSize, page: event.pageIndex }, this.startDate, this.endDate, this.textSearch);
  }
  search(textSearch: string) {
    this.textSearch = textSearch;
    this.loader = true;
}
}
