import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpService } from './http.service';
import { FormsDetails } from '../enums/forms-details';
import { FormFilterQuery, FormFilterQueryDemoL3 } from '../class/FormFilterQuery';
import { tap } from 'rxjs/operators';
import { fieldNames } from 'esri/core/sql/WhereClause';

@Injectable({
  providedIn: 'root'
})

export class FormContentDataService {
  public formsDataList;
  private formDataSource = new Subject<any>();
  public formDataSource$ = this.formDataSource.asObservable()
  private formsKeys = FormsDetails.definitions;
  public forms;
  private formSource = new Subject<any>();
  public formSource$ = this.formSource.asObservable()
  public demoL3Data;
  constructor(private httpService: HttpService) { }

  getAllForms() {
    if (this.forms && this.forms.length) {
      return of(this.forms);
    }
    else {
      return this.httpService.getAllForms().pipe(
        tap(res => this.forms = res)
      )
    }
  }
  getFormContentDataByFormDataQuery(componentId: number, limit: { limit: number, page: number }, startDate?: Date, endDate?: Date, textSearch?: string) {
    const relatedFormIds: Array<string> = this.formsKeys.find(e => e.componentId == componentId).relatedFormIds;
    const objs: FormFilterQuery = { formIdsArray: relatedFormIds, limit, startDate, endDate, textSearch };
    return this.httpService.getFilteredFormContent(objs).subscribe(res => {

      this.getAllForms().toPromise().then(() => {
        this.formsDataList = JSON.parse(res)
        this.emitData(this.formsDataList);
      })
    })
  }
  emitData(data) {

    // union between the fields definitions to related form
    const dataObj = data.data.map(t1 => ({ ...t1, ...this.forms.find(t2 => t2._id === t1.formId) }))
    console.log(...this.forms);

    const columnsArray = data.data.map(t1 => ({ ...t1, ...this.forms.find(t2 => t2._id === t1.formId) }))
      .map(w => w.fields.map(f => { return { fieldName: f.fieldName, type: f.type } }))
    // remove duplicate names of columns (in case of Promo activity, its important)
    let columnsSet = [];
    columnsArray.forEach(arr => columnsSet = columnsSet.concat(arr))
    let uniqueColumns = [...new Map(columnsSet.map(item => [item['fieldName'], item])).values()]

    //createdDate was sent seperately to the other fields, 
    uniqueColumns.unshift({ fieldName: "createdDate", type: 5 }, { fieldName: "createdBy", type: 5 }, { fieldName: "Activity", type: 1 })
    // pass data to the subscribed component

    const componentData = {
      data: dataObj.map(d => {
        if (d.form == undefined) {
          d['form'] = [];
        }
        d.form['createdDate'] = d.createdDate

        d.form['createdBy'] = d.createdBy

        d.form['Activity'] = d.name;
        return d.form
      }),
      displayedColumns: this.editColumnsStateDistrcit(uniqueColumns),
      total: data.count
    }
    this.formDataSource.next(componentData)
  }
  fixData(data) {
    // union between the fields definitions to related form
    const dataObj = data.data.map(t1 => ({ ...t1, ...this.forms.find(t2 => t2._id === t1.formId) }))
    console.log(...this.forms);

    const columnsArray = data.data.map(t1 => ({ ...t1, ...this.forms.find(t2 => t2._id === t1.formId) }))
      .map(w => w.fields.map(f => { return { fieldName: f.fieldName, type: f.type } }))
    // remove duplicate names of columns (in case of Promo activity, its important)
    let columnsSet = [];
    columnsArray.forEach(arr => columnsSet = columnsSet.concat(arr))
    let uniqueColumns = [...new Map(columnsSet.map(item => [item['fieldName'], item])).values()]

    //createdDate was sent seperately to the other fields, 
    uniqueColumns.unshift({ fieldName: "createdDate", type: 5 }, { fieldName: "createdBy", type: 5 }, { fieldName: "Activity", type: 1 })
    // pass data to the subscribed component

    const componentData =
      dataObj.map(d => {
        if (d.form == undefined) {
          d['form'] = [];
        }
        d.form['createdDate'] = d.createdDate

        d.form['createdBy'] = d.createdBy

        d.form['Activity'] = d.name;
        return d.form
      })
    return componentData;

  }
  downloadCsvFormContent(params, componentId) {
    const formIdsArray: Array<string> = this.formsKeys.find(e => e.componentId == componentId).relatedFormIds;
    params["formIdsArray"] = formIdsArray;
    return this.httpService.downloadCsvFormContent(params);
  }
  downloadCsvDemoL3(params) {
    return this.httpService.downloadCsvDemoL3(params);
  }
  editColumnsStateDistrcit(columns) {
    const indexStateDistrict = columns.findIndex(field => field.fieldName == 'State District')
    if (indexStateDistrict !== -1) {
      const stateField = { fieldName: "State", type: 1 }
      const districtField = { fieldName: "District", type: 1 }
      columns.splice(indexStateDistrict, 1, stateField, districtField)
    }
    return columns;
  }

  getDemoL3FormContentByFormDataQuery(limit: { limit: number, page: number }, startDate?: Date, endDate?: Date, textSearch?: string) {
    const objs: FormFilterQueryDemoL3 = { limit, startDate, endDate, textSearch };
    return this.httpService.getDemoL3FormContent(objs).subscribe(res => {
      this.demoL3Data = JSON.parse(res);
      this.emitDemoL3Data(this.demoL3Data);
    })
  }
  emitDemoL3Data(data) {
    // union between the fields definitions to related form
    const dataObj = data;
    const columnsArray = data.data.map(w => w.fields.map(f => { return { fieldName: f.fieldName, type: f.type } }))
    // data.map(t1 => ({ ...t1, ...this.forms.find(t2 => t2._id === t1.formId) }))
    // const columnsArray = data.map(t1 => ({ ...t1, ...this.forms.find(t2 => t2._id === t1.formId) }))
    //   .map(w => w.fields.map(f => { return { fieldName: f.fieldName, type: f.type } }))

    // remove duplicate names of columns (in case of Promo activity, its important)
    let columnsSet = [];
    columnsArray.forEach(arr => columnsSet = columnsSet.concat(arr))
    let uniqueColumns = [...new Map(columnsSet.map(item => [item['fieldName'], item])).values()]

    //createdDate was sent seperately to the other fields, 
    uniqueColumns.unshift({ fieldName: "createdDate", type: 5 }, { fieldName: "createdBy", type: 0 })
    // pass data to the subscribed component
    const componentData = {
      data: dataObj.data.map(d => {
        if (d.fields == undefined) {
          d['form'] = [];
        }
        d.form = d.fields.reduce(function (map, obj) { map[obj.fieldName] = obj.value; return map; }, {});
        d.form['createdDate'] = d.createdDate
        d.form['createdBy'] = d.createdBy;
        return d.form
      }),
      displayedColumns: this.editColumnsStateDistrcit(uniqueColumns),
      total: data.count
    }
    this.formDataSource.next(componentData)
  }
}
