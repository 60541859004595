import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ExportService } from 'src/app/services/export.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatSort } from '@angular/material';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { PopupComponent } from '../popup/popup.component';
import { CatalogMasterService } from 'src/app/services/catalog-master.service';
import { EmployeeMasterService } from 'src/app/services/employee-master.service';
import { CropMasterService } from 'src/app/services/crop-master.service';

@Component({
  selector: 'app-master-table',
  templateUrl: './master-table.component.html',
  styleUrls: ['./master-table.component.scss']
})
export class MasterTableComponent implements OnInit {
  @Input() listDataType;
  @Input() listData;
  @Input() tableTitle;
  @Input() displayedColumns: string[];
  @Input() action;
  @Input() stateDistrictList;
  @Output() saveChangeData = new EventEmitter();
  @Output() sendFilteredData = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() addClick = new EventEmitter();
  private showEditPopup: boolean = false;
  @Input() showFilter: boolean = false;
  public dataSource;
  public resultsLength: number = 0;
  public resultsPageSize: number = 10;
  public searchValue: string = "";
  public pageEvent;
  public pageSizeOptions;
  public range;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  headerNames: string[];
  constructor(private exportService: ExportService,private dialog: MatDialog,private catalogMasterService:CatalogMasterService,private employeeMasterService:EmployeeMasterService,private cropMasterService:CropMasterService) {
    this.dataSource = new MatTableDataSource<any>(this.listData);
    this.dataSource.paginator = this.paginator;
    this.listData ? this.resultsLength = this.listData.length : 0;
  }

  ngOnInit() {
    this.updateListData();
    this.headerNames = Object.keys(this.displayedColumns);
    console.log(this.displayedColumns, 'displayed colomns')
  }

  applyFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
    this.sendFilteredData.emit(this.dataSource.filteredData)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: any) {
    this.updateListData();
  }

  updateListData() {
    this.dataSource = new MatTableDataSource<any>(this.listData);
    this.listData ? this.resultsLength = this.listData.length : 0;
  }

  getEmailId(el) {
    return el['Email-id'];
  }
  openEditPopup(element) {
    let popupData = this.prepareEditPopupData(element);
    const dialogRef = this.dialog.open(PopupComponent, { data: popupData, panelClass: 'full-width-dialog' });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
         this.saveChangeData.emit(data);
      }
    })
  }
  prepareEditPopupData(data) {
    var fieldsArray;
    var type;
    if (this.listDataType == "StateDistrict") {
      fieldsArray = this.catalogMasterService.getEditStateDistrictFields(data,this.stateDistrictList);
      type = "State District";
    }
    if(this.listDataType=='CropFocus'){
      fieldsArray = this.cropMasterService.getEditCropFocusFields(data);
      type = "Crop Focus";
    }
    else if(this.listDataType == "User"){
      fieldsArray = this.employeeMasterService.getEditUserFields(data,this.stateDistrictList);
      type = "Employee";
    }
    var popupData =
    {
      id: data._id,
      title: "Edit " + this.listDataType,
      fields: fieldsArray,
      button: "Update",
      type: type
    };
    return popupData;
  }

  confirmDelete(element) {
    let deleteElement = this.listDataType === 'CropFocus' ? element.cropFocus : (element['Email-id'] ? element.FirstName + " " + element.LastName : element.State + ", " + element.District);
    swal.fire('Are you sure you want to delete' + ' ' + deleteElement + '?', "You won't be able to revert this!", 'warning')
      .then((result) => {
        if (result.value) {
          this.delete.emit(element);
        }
      })
  }
}
