
export class Session {

    constructor(private idToken: string, private accessToken: string, private refreshToken: string) { }

    getIdToken(): string {
        return this.idToken;
    }
    getAccessToken(): string {
        return this.accessToken;
    }
    getRefreshToken(): string {
        return this.refreshToken;
    }
}