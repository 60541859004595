import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AwsAuthError } from 'src/app/class/aws-entites.model';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public errorMessege:string;
  public showErrorMessege: boolean = false;
  public forgotPasswordData: FormGroup = new FormGroup({});
  private arrFormBuilder = {};
  constructor(private fb:FormBuilder,private loginService:LoginService,private router:Router) {
    this.setControlFields();
   }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
  }
  setControlFields() {
    this.arrFormBuilder = {};
    this.arrFormBuilder["email"] = ['', Validators.required];
    this.forgotPasswordData = this.fb.group(this.arrFormBuilder);
  }

  forgotPassword(){
    if(this.forgotPasswordData.controls.email.value){
      this.loginService.forgotPassword(this.forgotPasswordData.controls.email.value.toLowerCase()).then(res=>{
        this.router.navigateByUrl('/verification-email')
      }).catch((err: AwsAuthError) => {
          this.errorMessege = err.message;
          this.showErrorMessege = true;
        });
    }
    else{
      this.errorMessege = "Enter email";
      this.showErrorMessege = true;
    }
  }
}
