import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, of, throwError } from 'rxjs';
import { retry, shareReplay, distinctUntilChanged, catchError, switchMap, map, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../class/User';
import { LoginService } from './login.service';
import { FormFilterQuery, FormFilterQueryDemoL3 } from '../class/FormFilterQuery';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private baseUri = environment.httpUrl;
  protected buildHeaders(inHeaders: any = {}): HttpHeaders {
    let ret = new HttpHeaders();
    let user = JSON.parse(localStorage.getItem('loginUser'));
    user = new User(user.name, user._token)
    Object.assign(inHeaders, { authorization: "Bearer " + user.token });
    Object.keys(inHeaders).map(e => {
      ret = ret.append(e, inHeaders[e]);
    })
    return ret;
  }

  constructor(private httpClient: HttpClient, private router: Router, private route: ActivatedRoute, private loginService: LoginService) {
  }

  public getAllUsers() {
    return this.httpClient.get(this.baseUri + 'user/all', { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public addUser(params) {
    return this.httpClient.post(this.baseUri + 'user/create', params, { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public addUserInCognito(params) {
    return this.httpClient.post(this.baseUri + 'user/signUpInCognito', params, { headers: this.buildHeaders() });
  }
  public deleteUserFromCognito(params){
    return this.httpClient.post(this.baseUri + 'user/deleteUserFromCognito', params, { headers: this.buildHeaders() });
  }
  public deleteUser(params){
    return this.httpClient.post(this.baseUri + 'user/delete', params, { headers: this.buildHeaders() });
  }
  public updateUser(params){
    return this.httpClient.post(this.baseUri + 'user/update', params, { headers: this.buildHeaders() });
  }
  public getAllStateDistrict() {
    return this.httpClient.get(this.baseUri + 'state-district/stateDistrict', { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public addStatDistrict(params) {
    return this.httpClient.post(this.baseUri + 'state-district/create', params, { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public deleteStateDistrict(params){
    return this.httpClient.post(this.baseUri + 'state-district/delete', params, { headers: this.buildHeaders() });
  }
  public updateStateDistrict(params){
    return this.httpClient.post(this.baseUri + 'state-district/update', params, { headers: this.buildHeaders() });
  }
  public getAllForms() {
    return this.httpClient.get(this.baseUri + 'form/all', { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public getAllCrops() {
    return this.httpClient.get(this.baseUri + 'form/allCrops', { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public addCropFocus(params) {
    return this.httpClient.post(this.baseUri + 'form/createCrop', params, { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public deleteCropFocus(params){
    return this.httpClient.post(this.baseUri + 'form/deleteCrop', params, { headers: this.buildHeaders() });
  }
  public updateCropFocus(params){
    return this.httpClient.post(this.baseUri + 'form/update', params, { headers: this.buildHeaders() });
  }
  public downloadCsvFormContent(params) {
    return this.httpClient.post(this.baseUri + 'form-content/downloadCsv',params, { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public getAllFormsContent() {
    return this.httpClient.get(this.baseUri + 'form-content/all', { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public getFilteredFormContent(params:FormFilterQuery){
    return this.httpClient.post(this.baseUri + 'form-content/byQuery', params, { headers: this.buildHeaders(), responseType: 'text' }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public addCustomer(params) {
    return this.httpClient.post(this.baseUri + '/form-content/addCustomer', params, { headers: this.buildHeaders(), responseType: 'text' }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public getDemoL3FormContent(params:FormFilterQueryDemoL3){
    return this.httpClient.post(this.baseUri + 'demol3/byQuery', params, { headers: this.buildHeaders(), responseType: 'text' }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public downloadCsvDemoL3(params) {
    return this.httpClient.post(this.baseUri + 'demol3/downloadCsv',params, { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public sendForm(params) {
    return this.httpClient.post(this.baseUri + '/sendForm', params, { headers: this.buildHeaders(), responseType: 'text' }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }
  public getRoutingList() {
    return this.httpClient.get(this.baseUri + '/getRoutingList', { headers: this.buildHeaders() }).pipe(retry(3), catchError((err) => this.handleError(err)));
  }


  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      console.log(error)
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      switch (error.status) {
        case 401: {
          console.log("401 Unauthorized");
          // this.loginService.refreshToken();
          this.router.navigate(['/pages/login']);
          break;
        }
        case 0: {
          console.log("error code status 0")
          this.loginService.logOut();
          this.router.navigate(['/pages/login']);
          break;
        }
        default: {
          console.log("move to error");
          break;
        }
      }
    }
    //window.alert(errorMessage);
    console.log(errorMessage)
    return throwError(errorMessage);
  }


}
