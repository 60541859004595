import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from '../class/User';
import { catchError, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {
  private baseUri = environment.httpUrl;
  constructor(private http: HttpClient) { }
  protected buildHeaders(inHeaders: any = {}): HttpHeaders {
    let ret = new HttpHeaders();
    let user = JSON.parse(localStorage.getItem('loginUser'));
    user = new User(user.name, user._token)
    Object.assign(inHeaders, { authorization: "Bearer " + user.token });
    Object.keys(inHeaders).map(e => {
      ret = ret.append(e, inHeaders[e]);
    })
    return ret;
  }
  get<T>(url, data?): Observable<T>  | unknown{
    var options = { headers: this.buildHeaders() };
    if (typeof data !== 'undefined') {
      options['params'] = data;
    }
    return this.http.get<T>(this.baseUri + url, options)
  }

  post<T>(url, data): Observable<T> | unknown{
    return this.http.post<T>(this.baseUri + url, data, { headers: this.buildHeaders() })
  }
  put<T>(url, data): Observable<T> {
    return this.http.put<T>(this.baseUri + url, data, { headers: this.buildHeaders() })
  }
  delete<T>(url): Observable<T> {
    return this.http.delete<T>(this.baseUri + url, { headers: this.buildHeaders() })
  }
}