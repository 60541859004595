import { Injectable } from '@angular/core';
import { Field } from '../class/Field';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeMasterService {

  constructor(private httpHandler: HttpHandlerService) { }

  getNewEmployeeFields(states,stateDistrictList): Array<Field> {

    return this.getDemoJsonNewEmployeeFields(states,stateDistrictList);

  }
  getEditUserFields(data,stateDistrictList){
    return [
      {
        fieldName: "ClientName",
        type: 1,
        addRow: false,
        isRequired: true,
        placeholder: "Client Name",
        value: data.ClientName,
        valuesDropDown: ["ICL-GROUP"]
      },
      {
        fieldName: "FirstName",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "First Name",
        value: data.FirstName,
        valuesDropDown: []
      },
      {
        fieldName: "LastName",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Last Name",
        value: data.LastName,
        valuesDropDown: []
      },
      {
        fieldName: "Mobile",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Mobile",
        value: data.Mobile,
        valuesDropDown: []
      },
      {
        fieldName: "State",
        type: 1,
        addRow: false,
        isRequired: false,
        placeholder: "State",
        value: data.State,
        generalValues:stateDistrictList.state,
        valuesDropDown: stateDistrictList.state
      },
      {
        fieldName: "District",
        type: 1,
        addRow: false,
        isRequired: false,
        placeholder: "District",
        value: data.District,
        generalValues:stateDistrictList.stateDistrict,
        valuesDropDown: []
      },
      // {
      //   fieldName: "Location",
      //   type: 1,
      //   addRow: false,
      //   isRequired: true,
      //   placeholder: "Location",
      //   value: data.Location,
      //   generalValues:stateDistrictList.districtlocation,
      //   valuesDropDown: []
      // },
      {
        fieldName: "RegionalOffice",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Regional Office",
        value: data.RegionalOffice,
        valuesDropDown: []
      },

      {
        fieldName: "Territory",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Territory",
        value: data.Territory,
        valuesDropDown: []
      },
      {
        fieldName: "Headquarter",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Headquarter",
        value: data.Headquarter,
        valuesDropDown: []
      },
      {
        fieldName: "Email-id",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Email-id",
        value: data['Email-id'],
        valuesDropDown: []
      },
      {
        fieldName: "Role",
        type: 1,
        addRow: false,
        isRequired: true,
        placeholder: "Role",
        value: data.Role,
        valuesDropDown: ["FA", "Supervisor", "Manager"]
      },
      // {
      //   fieldName: "Password",
      //   type: 0,
      //   addRow: false,
      //   isRequired: true,
      //   placeholder: "Password",
      //   value: data.Password,
      //   valuesDropDown: []
      // }
    ]
  }
  getDemoJsonNewEmployeeFields(states,stateDistrictList): Array<Field> {
    // inputText=0,selectOption=1,file=2,inputNumber=3,textArea=4
    return [
      {
        fieldName: "ClientName",
        type: 1,
        addRow: false,
        isRequired: true,
        placeholder: "Client Name",
        valuesDropDown: ["ICL-GROUP"]
      },
      {
        fieldName: "FirstName",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "First Name",
        valuesDropDown: []
      },
      {
        fieldName: "LastName",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Last Name",
        valuesDropDown: []
      },
      {
        fieldName: "Mobile",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Mobile",
        valuesDropDown: []
      },
      {
        fieldName: "State",
        type: 1,
        addRow: false,
        isRequired: false,
        placeholder: "State",
        generalValues:stateDistrictList.state,
        valuesDropDown: stateDistrictList.state
      },
      {
        fieldName: "District",
        type: 1,
        addRow: false,
        isRequired: false,
        placeholder: "District",
        generalValues:stateDistrictList.stateDistrict,
        valuesDropDown: []
      },
      // {
      //   fieldName: "Location",
      //   type: 1,
      //   addRow: false,
      //   isRequired: true,
      //   placeholder: "Location",
      //   generalValues:stateDistrictList.districtlocation,
      //   valuesDropDown: []
      // },
      {
        fieldName: "RegionalOffice",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Regional Office",
        valuesDropDown: []
      },

      {
        fieldName: "Territory",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Territory",
        valuesDropDown: []
      },
      {
        fieldName: "Headquarter",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Headquarter",
        valuesDropDown: []
      },
      {
        fieldName: "Email-id",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Email-id",
        valuesDropDown: []
      },
      {
        fieldName: "Role",
        type: 1,
        addRow: false,
        isRequired: true,
        placeholder: "Role",
        valuesDropDown: ["FA", "Supervisor", "Manager"]
      },
      {
        fieldName: "Password",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Password",
        valuesDropDown: []
      }
    ]
  }
}
