import { Injectable } from '@angular/core';
import { Field } from '../class/Field';

@Injectable({
  providedIn: 'root'
})
export class CatalogMasterService {

  constructor() { }

  getNewCatalogFields(stateDistrictList): Array<Field> {
    return this.getDemoJsonNewCatalogFields(stateDistrictList);

  }
  getEditStateDistrictFields(data,stateDistrictList){
    return [
      {
        fieldName: "State",
        type: 1,
        addRow: false,
        isRequired: true,
        placeholder: "State",
        value: data.State,
        generalValues:stateDistrictList.state,
        valuesDropDown: stateDistrictList.state
      },
      {
        fieldName: "District",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "District",
        value: data.District,
        generalValues:stateDistrictList.stateDistrict,
        valuesDropDown: []
      },
      {
        fieldName: "Location",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Multiple Location, separate with a comma: London, Paris, New York",
        value: data.Location.join(','),
        generalValues:stateDistrictList.districtlocation,
        valuesDropDown: []
      }
    ]
  }
  getDemoJsonNewCatalogFields(stateDistrictList): Array<Field> {
    // inputText=0,selectOption=1,file=2,inputNumber=3,textArea=4
    return [
      {
        fieldName: "State",
        type: 1,
        addRow: false,
        isRequired: true,
        placeholder: "State",
        generalValues:stateDistrictList.state,
        valuesDropDown:stateDistrictList.state
      },
      {
        fieldName: "District",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "District",
        generalValues:stateDistrictList.stateDistrict,
        valuesDropDown: []
      },
      {
        fieldName: "Location",
        type: 0,
        addRow: false,
        isRequired: true,
        placeholder: "Multiple Location, separate with a comma: London, Paris, New York",
        generalValues:stateDistrictList.districtlocation,
        valuesDropDown: []
      }
    ]
  }
}
